/* CSS standard */

.navigation {
    margin: 26px 0;
    display: flex;
}

.navigation a {
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    color: white;


}

.navigation a:hover {
    transform: scale(1.5);
}

.navigation .active a {
    color: white;
}

.navigation .active::after {
    content: '';
    width: 85%;
    height: 5px;
    background: #90fabc;
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}

/* Styles pour les écrans de petite taille */
.navigation {
    margin: 10px 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.navigation a {
    margin: 5px;
    padding: 5px;
    font-size: 1rem;
    font-family:cursive;
    filter:drop-shadow(2px 2px 0px black);
}

@media screen and (max-width: 768px) {
    .navigation {
        display: none;
    }

    .btnSide {
        display: block;
        padding: 5px;
    }

}

/* Styles pour les écrans de taille moyenne et plus grands */
@media screen and (min-width: 768px) {
    .navigation {
        flex-direction: row;
        justify-content: center;
    }

    .navigation a {
        font-size: 1.2rem;
        margin: 10px;
    }

    .btnSide {
        display: none;
    }
    .bidule{
        display: none;
    }
}