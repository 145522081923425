.video {
    /* align-items: center; */
    padding: 10px;
    display: flex;
    justify-content: center;
    flex: 1;
}

.image-left {
    width: 100%; 
    max-width: 10%;
    height: 100%;
    float: left; 
    margin-right: 20px; 

}

.image-right {
    width: 100%; 
    max-width: 10%;
    height: 100%;
    float: right; 
    margin-right: 20px; 

}

@media screen and (max-width: 768px) {
    .image-left{
        display: none;
    }
    .image-right{
        display: none;
    }
    .guitaretitre{
        text-align: center; 
        color:white; 
        font-weight: bold; 
        font-family: cursive;
    }
}

@media screen and (min-width: 768px) {
    .guitaretitre{
        text-align: center; 
        color:white; 
        font-weight: bold; 
        font-family: cursive;
        filter:drop-shadow(2px 2px 0px black); 
    }
}
  