body {
    background-image: url('../../assets/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    /* height: 100%; */
    box-sizing: border-box;
    min-height: 100%;
    padding-bottom: 175px


}
html{
    height: 100%;
}



/* .overlay {
      
    background: linear-gradient(to right, #305840, #90fabc);
} */