/* CSS standard */

.bidule {
    position: fixed;
    top: -100%;
    left: 0;
    width: 40%;
    height: 100%;
    background-color: #64a135;
    transition: top 0.3s ease-in-out;
    border-radius: 10;
    
}

.bidule.hidden {
    top: -100%;
}

.bidule.visible {
    top: 0;
    
}

/* .bidule a:hover {
    transform: scale(1.5);
} */

.bidule .active a {
    color: #ec0e0e;
}

.bidule a {
    /* margin: 10px; */
    /* padding: 10px; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
    border-radius: 4px;
    position: relative;
    font-size: 1.7rem;
    text-decoration: none;
    font-weight: bold;
    color: black;


}