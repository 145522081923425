.guitare {
  height: 500px;
  width: 100%;
}

.guitarefolk {
  height: 480px;
  width: 50%;
  margin-left: 25%;

}

@media screen and (min-width: 768px) {
  .hometxt {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: cursive;
    filter: drop-shadow(2px 2px 0px black);
  }

  .hometitre{
   
       text-align: center;
       color: white;
       font-family:cursive;
       filter: drop-shadow(2px 2px 0px black);

  }
}

@media screen and (max-width: 768px) {
  .guitare {
    height: 150px;
    width: 100%;
  }

  .guitarefolk {
    height: 150px;
    width: 50%;
    /* margin-top: 20px; */
  }

  .hometxt {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: cursive;
  
  }
  .hometitre{
   
    text-align: center;
    color: white;
    font-family:cursive;

    
}
}