.infoPage{
    /* display: flex; */
    /* padding: %; */
    margin: 5%;
    background-color: rgba(17, 204, 145, 0.8);
    color: #fff;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
}
.infotxt{
    color:#000;
    font-weight: bold;
    font-family:cursive;
}