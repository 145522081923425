/* Styles pour le pied de page */
/* Styles pour le pied de page */
.footerPage {
    background-color: #333;
    color: #fff;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    position:absolute;
    bottom:0;
    right: 10px;
    left: 10px;
    

}


/* Ajoute de l'espace en bas du contenu pour éviter que le footer ne le cache */
.content {  
 /* margin-bottom: 75px;   */
 height: 100%;
}


.link :hover {
    color: #6b3fa0;
    /* transform: scale(1.2); */
}

.linkedin :hover {
    color: #0e76a8;
    /* transform: scale(1.2); */
}

@media screen and (max-width: 768px) {
    .footertxt{
        cursor: pointer; 
        font-family:cursive;
        font-size: large; 
    }

  .footertitre{
    text-decoration-line: underline;
     font-family:cursive; 
     font-size: large;
  }

}

@media screen and (min-width: 768px) {
    .footertxt{
        cursor: pointer; 
        font-family:cursive;
        filter:drop-shadow(2px 2px 0px black); 
    }

  .footertitre{
    text-decoration-line: underline;
     font-family:cursive; 
     filter:drop-shadow(2px 2px 0px black);
  }

}